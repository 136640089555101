.App {
  text-align: center;
}

.App-logo {
  
  height: 5vmin;
  margin: 2vh;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.radar {
  margin: auto;
  flex-grow: 10;
  min-width: 40vw;
  max-height: 30vh;
}
.bar {
  margin: auto;
  flex-grow: 10;
  max-height: 30vh;
  margin-left: -1vw;
}

.ai-text {

  border: #7ab2b25c;
  border-style: solid;
  border-width: 0.15vw;
  height: 10vh;
  border-radius: 1.2vw;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  font-size: calc(4px + 2vmin);
  color: #7AB2B2;
}

.App-header {
  background-color: #fff;
  min-height: 10vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #7AB2B2;
}

.boxes {
  margin: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.contentBox {
  min-width: 40vw;
  max-width: 40vw;
  min-height: 70vh;
  border: #7ab2b295;
  float: right;
  margin-left: 2vw;
  margin-right: 2vw;
  border-style: solid;
  border-width: 0.15vw;
  border-radius: 1vw;

  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
}
.chat-bubble {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 95%;
  margin-left: 0.5vw;
  margin-top: 0.5vh;
  
  
}
.chartContainer {
  margin:auto;
  max-height: 10vh;
}
.body {
  min-height: 80vh;
}

.menu {
  display: inline-flex;
  font-weight: bolder;
  gap: 1vw;
}
.non-center {
  max-height: 0.5vh;
  
}
.sub-menu {
  font-size: calc(6px + 2vmin);
  display: inline-flex;
  font-weight: bolder;
  margin-left: 12%;
  gap: 1vw;
  margin: auto;
}
.lang-select {
  border:none;
  color: #7AB2B2;
  font-weight: bolder;
  font-size: large;
  margin-left: 3vw;
  margin-top: 1vh;
  margin-right: 1vw;
}
.selection {
  width: 20vw;
  color: #7AB2B2;
}

.centerSelection {
  width: 30vw;
  
}

.colored-text {
  font-weight: bold;
  font-size: calc(6px + 2vmin);
  color: #7AB2B2;
}
.App-link {
  color: #61dafb;
}

.footer {
  font-size: calc(2px + 1.5vmin);
  color: #7AB2B2;
  margin-top: auto;
}

.recharts-layer .recharts-polar-angle-axis-tick-value {
  font-size: medium;
  font-weight: bolder;
  color: #7AB2B2;
  fill: #7AB2B2;
}
.smallerCenterText {
  font-size:medium;
}

@keyframes App-logo-spin {
  from {
    transform: scale(90)
  }

  to {
    transform: scale(100)
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .sub-menu {
    flex-direction: column;
    gap: 10px;
  }
  .smallerCenterText {
    font-size:small;
  }
  .average-text {
    margin-top: 7vh;
  }
 
  .contentBox {
    min-height: 70vh;
    margin-top: 1vh;
  }
  .selection, .centerSelection {
    margin-top: 6vh;
    width: 100%;
    max-width: 50vw;
  }
  .recharts-default-legend {
    margin-bottom: -8vh !important;
  }
  .recharts-legend-item {
    margin-bottom: -2vh;
  }
  .recharts-wrapper .radar {
    max-width: 230%;
    height: 20vh !important;
  }
  .recharts-layer .recharts-polar-angle-axis-tick-value {
    font-size: larger;
    font-weight: bolder;
    color: #7AB2B2;
    fill: #7AB2B2;
  }
  .radar {
    height: 20vh !important;
  }
  .body {
    flex-direction: column;
    align-items: center;
  }

  .contentBox {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .App-header {
    font-size: calc(8px + 2vmin);
  }
}